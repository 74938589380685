/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
 import React from 'react';
 import { Link } from "gatsby";
 import { GatsbyImage, getImage } from 'gatsby-plugin-image';
 import Block from '../../../components/block';
 import "../../../../../blocks/acf/template-single-post/assets/css/style.css";

 const AcfTemplateSinglePost = ({ block, post = null }) => {

	if( !block ){
		return null;
	}

	const {
		attributesJSON,
		innerBlocks
	} = block;


	if( !attributesJSON ){
		return null;
	}

	const attributes = JSON.parse(attributesJSON);

	if( !attributes ){
		return null;
	}

	const {
		title,
		featuredImage
	} = post;

	const image = featuredImage ? getImage(featuredImage.node.localFile) : null;


	return (
		<div className="template-single-post">
			<div className="template-single-post__intro contentarea">
				<Link className="template-single-post__backhome" to="/">&lt;&lt; Back to Homepage</Link>
				{ title && <h1 dangerouslySetInnerHTML={{__html: title}}></h1> }
				{ image && <GatsbyImage className="template-single-post__image" image={image} alt={featuredImage.node.altText} /> }
			</div>
			<div className="template-single-post__content contentarea">
				{
					innerBlocks.map((innerBlock, index) => {
						return <Block key={index} block={innerBlock} post={post} />;
					})
				}
			</div>
		</div>
	)
 }
 
 export default AcfTemplateSinglePost;
  
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
 import React from 'react';
 import { useStaticQuery, graphql } from "gatsby";
 import { GatsbyImage, getImage } from 'gatsby-plugin-image';
 import "../../../../../blocks/acf/three-latest-posts/assets/css/style.css";

 const AcfThreeLatestPosts = ({ block }) => {

	const {
		allWpPost: { 
			nodes: posts 
		},
	} = useStaticQuery(graphql`
		query {
			allWpPost(limit: 3) {
				nodes {
				  featuredImage {
					node {
					  altText
					  localFile {
						childImageSharp {
						  gatsbyImageData
						}
					  }
					}
				  }
				  title
				  link
				}
			}
		}
	`);

	if( !block ){
		return null;
	}

	const {
		attributesJSON
	} = block;


	if( !attributesJSON ){
		return null;
	}

	const attributes = JSON.parse(attributesJSON);

	if( !attributes ){
		return null;
	}

	const {
		data
	} = attributes;

	return (
		<div className="three-latest-posts contentarea">
			{ data?.heading && <h2 dangerouslySetInnerHTML={{__html: data.heading}}></h2> }
			<div className="three-latest-posts__postcontainer contentarea">
				{ posts.map((post, index) => {
					const image = getImage(post.featuredImage.node.localFile);
					return (
						<a key={index} className="post" href={post.link}>
							<article className="post__card">
								<GatsbyImage className="post__image" image={image} alt={post.featuredImage.node.altText} />
								<h1 className="post__title" dangerouslySetInnerHTML={{__html: post.title}}></h1>
								<span className="post__readmore">Read more</span>
							</article>
						</a>
					)
				})}
			</div>
		</div>
	)
 }
 
 export default AcfThreeLatestPosts;
  
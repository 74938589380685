/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
 import React from 'react';

 const CoreParagraph = ({ block }) => {

	if( !block ){
		return null;
	}

	const {
		attributesJSON,
	} = block;

	if( !attributesJSON ){
		return null;
	}

	const attributes = JSON.parse(attributesJSON);

	if( !attributes ){
		return null;
	}

	const {
		content,
		dropCap,
		anchor
	} = attributes;

	const pAttributes = {
		id: anchor ? anchor : null,
		className: dropCap ? "has-drop-cap" : null,
		dangerouslySetInnerHTML: {
			__html: content
		}
	}

	return (
		<p {...pAttributes}></p>
	)
 }
 
 export default CoreParagraph;
  
import React from 'react';

const CoreQuote = ({ block }) => {
	 
	if( !block ){
		return null;
	}
 
	const {
		attributesJSON,
	} = block;
 
	if( !attributesJSON ){
		return null;
	}
 
	const attributes = JSON.parse(attributesJSON);
 
	if( !attributes ){
		return null;
	}
 
	const {
		value,
		citation
	} = attributes;

	const dynamicValue = citation ? value + `<cite>${citation}</cite>` : value;

	return <blockquote className="wp-block-quote" dangerouslySetInnerHTML={{__html: dynamicValue}}></blockquote>;
}
 
export default CoreQuote;
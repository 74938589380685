/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
 import React from 'react';
 import Block from '../../../components/block';
 import "../../../../../blocks/acf/template-front-page/assets/css/style.css";

 const AcfTemplateFrontPage = ({ block, post = null }) => {

	if( !block ){
		return null;
	}

	const {
		attributesJSON,
		innerBlocks
	} = block;


	if( !attributesJSON ){
		return null;
	}

	const attributes = JSON.parse(attributesJSON);

	if( !attributes ){
		return null;
	}

	const {
		data
	} = attributes;
	

	return (
		<div className="template-front-page">
			<div className="template-front-page__intro contentarea">
				{ data.heading && <h1 dangerouslySetInnerHTML={{__html: data.heading}}></h1> }
				{ data['intro_paragraph'] && <p dangerouslySetInnerHTML={{__html: data['intro_paragraph']}}></p> }
			</div>
			<div className="template-front-page__content contentarea">
				{
					innerBlocks.map((innerBlock, index) => {
						return <Block key={index} block={innerBlock} post={post} />;
					})
				}
			</div>
		</div>
	)
 }
 
 export default AcfTemplateFrontPage;
  
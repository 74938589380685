/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
 import React, { useState } from 'react';
 import Block from "../../../components/block";
 import "../../../../../blocks/acf/interior-content-accordion/assets/css/style.css";
 import Arrow from "../../../../../assets/images/arrow.svg";


 const AcfInteriorContentAccordion = ({ block }) => {

	const [accordionIsOpen, setAccordionIsOpen] = useState(false);

	const toggleAccordion = () => {
		setAccordionIsOpen(!accordionIsOpen);
	}

	const onKeyUp = (event) => {
		if (event.charCode === 13) {
			//enter key
		  	toggleAccordion();
		}
	}

	if( !block ){
		return null;
	}

	const {
		attributesJSON,
		innerBlocks
	} = block;

	if( !attributesJSON ){
		return null;
	}

	const attributes = JSON.parse(attributesJSON);

	if( !attributes ){
		return null;
	}

	const {
		data: {
			heading
		}
	} = attributes;

	return (
		<div className="interior-content-accordion">
			<button className={accordionIsOpen ? "interior-content-accordion__toggle-open" : "interior-content-accordion__toggle"} onClick={toggleAccordion} onKeyPress={()=>{onKeyUp()}}>
				{heading}
				<Arrow className="arrow" />
			</button>
			<div className={accordionIsOpen ? "interior-content-accordion__inner-open" : "interior-content-accordion__inner"}>
				{
					innerBlocks && innerBlocks.map((innerBlock, index) => {
						return <Block key={index} block={innerBlock} />;
					})
				}
			</div>
		</div>
	)
 }
 
 export default AcfInteriorContentAccordion;
  
import React from 'react';

const CoreList = ({ block }) => {
	 
	if( !block ){
		return null;
	}
 
	const {
		attributesJSON,
	} = block;
 
	if( !attributesJSON ){
		return null;
	}
 
	const attributes = JSON.parse(attributesJSON);
 
	if( !attributes ){
		return null;
	}
	
	const {
		ordered,
		anchor,
		values
	} = attributes;

	const dynamicAttributes = {
		id: anchor,
		dangerouslySetInnerHTML: {
			__html: values
		}
	};

	return ordered ? <ol {...dynamicAttributes}></ol> : <ul {...dynamicAttributes}></ul>;
}
 
export default CoreList;
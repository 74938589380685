import React from 'react';
import AcfInteriorContentAccordion from "../../blocks/acf/interior-content-accordion/index.js";
import AcfTemplateSinglePost from "../../blocks/acf/template-single-post/index.js";
import AcfTemplateFrontPage from "../../blocks/acf/template-front-page/index.js";
import AcfThreeLatestPosts from '../../blocks/acf/three-latest-posts/index.js';
import CoreHeading from "../../blocks/core/heading";
import CoreParagraph from "../../blocks/core/paragraph";
import CoreImage from "../../blocks/core/image";
import CoreQuote from '../../blocks/core/quote/index.js';
import CoreList from '../../blocks/core/list/index.js';
import GravityFormsForm from "../../blocks/gravityforms/form";

const Block = ({ block, post = null }) => {
	switch( block.name ){
		case "core/paragraph":
			return <CoreParagraph block={block} />;
		case "core/heading":
			return <CoreHeading block={block} />;
		case "core/image":
			return <CoreImage block={block} />;
		case "core/quote":
			return <CoreQuote block={block} />;
		case "core/list":
			return <CoreList block={block} />;
		case "acf/interior-content-accordion":
			return <AcfInteriorContentAccordion block={block} />;
		case "acf/template-single-post":
			return <AcfTemplateSinglePost block={block} post={post} />;
		case "acf/template-front-page":
			return <AcfTemplateFrontPage block={block} post={post} />;
		case "acf/three-latest-posts":
			return <AcfThreeLatestPosts block={block} />;
		case "gravityforms/form":
			return <GravityFormsForm block={block} />;
		default:
			return null;
	}
}

export default Block;
 
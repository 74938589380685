import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const ImageSizeStyling = (attributes) => {
	const {
		width,
		height,
		sizeSlug
	} = attributes;

	if( width || height ){
		return {
			width: `${width}px`,
			height: `${height}px`
		};
	} else {
		if( sizeSlug ){
			switch( sizeSlug ){
				case 'thumbnail':
					return {
						width: `150px`,
						height: `150px`
					};
				default:
					return null; 
			}
		} else {
			return null;
		}
	}

};

const ImageClasses = (attributes) => {
	return attributes.align ? `wp-block-image align${attributes.align}` : 'wp-block-image';
};

const CoreImage = ({ block }) => {
	const {
		allWpMediaItem: { 
			nodes: mediaItems 
		},
	} = useStaticQuery(graphql`
		query {
			allWpMediaItem {
				nodes {
					altText
					databaseId
					localFile {
						childImageSharp {
							gatsbyImageData
						}
					}
				}
			}
		}
	`);

	if( !block ){
		return null;
	}

	const {
		attributesJSON,
	} = block;

	if( !attributesJSON ){
		return null;
	}

	const attributes = JSON.parse(attributesJSON);

	if( !attributes ){
		return null;
	}

	const {
		id,
		altText,
		align,
		anchor,
	} = attributes;

	const mediaItem = mediaItems.find(item => item.databaseId === id);

	const image = mediaItem ? getImage(mediaItem.localFile) : null;

	const dynamicAttributes = {
		image: image,
		alt: altText ? altText : '',
		'data-align': align,
		id: anchor,
		className: ImageClasses(attributes),
		style: ImageSizeStyling(attributes)
	};

	return image ? <GatsbyImage {...dynamicAttributes} data-align={align} /> : null;
}

export default CoreImage;
  
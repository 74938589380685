/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
 import React, { useEffect, useRef } from 'react';

 const GravityFormsForm = ({ block }) => {

	const iframeInterval = useRef();

	useEffect(()=>{
		if( typeof window !== undefined ){
		
			var iframes = document.getElementsByTagName( 'iframe' );
		
			window.addEventListener( 'message', function( e ) {
				var iframe;
		
				if ( 'size' === e.data.message ) {
					iframe = iframes[ e.data.index ];
		
					if ( 'undefined' !== typeof iframe ) {
						iframe.height = parseInt( e.data.height, 10 );
						iframe.scrolling = 'no';
					}
				}
			} );
		
			function watchIframe( i ) {
				iframes[ i ].onload = iframes[ i ].onreadystatechange = function() {
					if ( this.readyState && 'complete' !== this.readyState && 'loaded' !== this.readyState ) {
						return;
					}
		
					iframeInterval.current = setInterval( function() {
						// Send a message to the iframe to ask it to return its size.
						iframes[ i ].contentWindow.postMessage({
							message: 'size',
							index: i
						}, '*' );
					}, 500 );
				};
			};
		
			if ( iframes.length ) {
				for ( var i = 0; i < iframes.length; i ++ ) {
					if ( -1 === iframes[ i ].className.indexOf( 'gfiframe' ) ) {
						continue;
					}
		
					watchIframe( i );
				}
			}

		} 

		return () => {
			clearInterval(iframeInterval.current);
			window.removeEventListener("message", ()=>{});
		}
		
	},[]);
	 
	if( !block ){
		return null;
	}
 
	const {
		attributesJSON,
	} = block;

	if( !attributesJSON ){
		return null;
	}

	const attributes = JSON.parse(attributesJSON);

	if( !attributes ){
		return null;
	}

	const {
		formId
	} = attributes;


	return <iframe title="Gravity Form" src={`${process.env.GATSBY_WP_BASE_URL}/gfembed?f=` + formId} width="100%" frameBorder="0" className="gfiframe"></iframe>;
  }
  
  export default GravityFormsForm;
   